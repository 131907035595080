import React, { Component } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import ReactGA from 'react-ga';
import Loading from './components/utils/Loading';
import Home from './components/mainapp/Home';
import AgeVerification from './components/mainapp/AgeVerification';
import RewardViewer from './components/utils/PrizeViewer';
import RedeemedViewer from './components/utils/RedeemedViewer';
import { base } from './base';
import Login from './components/mainapp/Login';
import { getDistance } from 'geolib';
import FallbackLocalStorage from "fallback-local-storage";
import {getURLParameter} from "./components/utils/HelpfulFunction";
let appStorage;
if(process.env.NODE_ENV === "production"){
    ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}

function PrivateRoute({ authenticated, children }) {
    return authenticated === true ? children : <Navigate to="/login" />;
}

function logPageView(path) {
    path = path || window.location.pathname;
    if(process.env.NODE_ENV === "production" && process.env.REACT_APP_GA_KEY){
        ReactGA.set({ page: path });
        ReactGA.pageview(path);
    }
}


class App extends Component {
    constructor(props) {
        super(props);
        this.setCurrentUser = this.setCurrentUser.bind(this);
        let userEmail;
        try {
            userEmail = appStorage.getItem('userEmail') || false;
        } catch (e) {
            userEmail = false
        }
        // this.setTheme();
        this.state = {
            admin: false,
            authenticated: !!userEmail,
            currentUser: null,
            loading: true,
            tenantVariables: null,
            latitude: null,
            longitude: null
        }
    }

    getLocation(tenantVariables, stringConstants){
      return new Promise((resolve, reject) => {

        if (!navigator.geolocation) {
          reject(stringConstants.GEOLOCATIONNOTSUPPORTED);
        } else {
          const toCheckLatitude = tenantVariables.latitude || 51.525;
          const toCheckLongitude = tenantVariables.longitude || 7.4575;
          navigator.geolocation.getCurrentPosition(
              function(position) {
                  resolve({distance: getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                          latitude: toCheckLatitude,
                          longitude: toCheckLongitude,
                      }), lat: toCheckLatitude, long: toCheckLongitude})
              },
              (err) => {
                  if(err.message === "User denied Geolocation"){
                    reject(stringConstants.NEEDBROWSERPERMISSION);
                  } else {
                    reject(stringConstants.UNKNOWNERROROCCURED);
                  }
              }
          );
        }
      })
    }

    setCurrentUser() {
        const userEmail = appStorage.getItem('userEmail') || false;
        this.setState({
            authenticated: !!userEmail
        })
    }

    checkForAgeGate(tenantVariables){
      const variable_age_boolean = tenantVariables.collectBirthday || false;
      if(!variable_age_boolean){
        return true
      }
      const formBirthday = tenantVariables.formBirthday || false;
      let variable_age = tenantVariables.allowedAge || 21;
      variable_age = parseInt(variable_age, 10);
      let user_age = appStorage.getItem('verifiedAge') || false;
      if(isNaN(parseInt(user_age))){
        user_age = false
      } else {
        user_age = parseInt(user_age, 10)
      }
      return (user_age && user_age >= variable_age) || formBirthday;
    }

    setUpStorage(variables){
        const keepFansLoggedIn = variables.keepFansLoggedIn || false;
        if (FallbackLocalStorage.getStorage().includes("sessionStorage") && !keepFansLoggedIn) {
            // Here we don't have any problems
            // with writing to `window.localStorage`
            appStorage = globalThis.sessionStorage;
        } else if(FallbackLocalStorage.getStorage().includes("localStorage") && keepFansLoggedIn) {
            appStorage = globalThis.localStorage;
        } else {
            // Looks like we have some troubles.
            // Browser has disable `window.localStorage` support.
            // Or browser is in `Private Mode`
            // which disables localStorage completely.
            appStorage = new FallbackLocalStorage();
        }
    }

    componentDidMount() {
      let language = getURLParameter('language') || "en";
      window.history.pushState("",document.title,window.location.pathname+window.location.search);
      this.loadLanguage(language);
      this.tenantRulesRef = base.listenToDoc(`tenantRules/default`, {
          context: this,
          then(data){
              this.setState({
                  tenantRules: data,
                  loading: false
              })
          },
          onFailure(err) {
              console.log("RULES")
              console.log(err)
          }
      });
      this.tenantVariablesRef = base.listenToDoc(`tenantVariables/default`, {
          context: this,
          then(data){
              document.title = data.pageTitle || "";
              // if(data.backgroundImage){
              //     document.getElementById('root').style.backgroundImage = "url("+ data.backgroundImage.split(' ').join('+')+")";
              // }
              this.setState({
                  tenantVariables: data,
                  loading: false
              })
              this.setUpStorage(data);
          },
          onFailure(err) {
            console.log("Variables")
            console.log(err)
          }
      })
    }

    componentWillUnmount(){
        base.removeBinding(this.tenantRulesRef);
        base.removeBinding(this.tenantVariablesRef);
    }

    async loadLanguage(language){
      let languagesConstants;
      try {
        languagesConstants = await import(`./constants/languages/${language}.js`);
      } catch (e) {
        languagesConstants = await import(`./constants/languages/en.js`);
      }
      this.setState({
        lanaguageConstants: languagesConstants
      })
    }

    render() {
        const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
        if (this.state.loading === true) {
            return (
                <Loading loading={this.state.loading}/>
            )
        }
        return (
            <BrowserRouter>
                <Routes>

                    <Route
                        path="/login"
                        element={<Login appStorage={appStorage} authenticated={this.state.authenticated} loadPage={() => logPageView()} setCurrentUser={this.setCurrentUser} tenantVariables={this.state.tenantVariables} tenantRules={this.state.tenantRules} checkForAgeGate={this.checkForAgeGate} isMlbTeam={isMlbApp} stringConstants = {this.state.lanaguageConstants} />}
                    />

                    <Route
                        path="/"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <Home appStorage={appStorage} tenantVariables={this.state.tenantVariables} tenantRules={this.state.tenantRules} passedEmail={this.state.passedEmail} loadPage={() => logPageView()} checkForAgeGate={this.checkForAgeGate} isMlbTeam={isMlbApp} stringConstants={this.state.lanaguageConstants} getLocation={this.getLocation} />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/age_gate"
                        element={<AgeVerification appStorage={appStorage} loadPage={() => logPageView()} tenantVariables={this.state.tenantVariables} tenantRules={this.state.tenantRules} checkForAgeGate={this.checkForAgeGate} isMlbTeam={isMlbApp} stringConstants = {this.state.lanaguageConstants} />}
                    />

                    <Route
                        path="/prizeviewer"
                        element={<RewardViewer appStorage={appStorage} loadPage={() => logPageView()} />}
                    />

                    <Route
                        path="/redeemviewer"
                        element={<RedeemedViewer appStorage={appStorage} loadPage={() => logPageView()} stringConstants={this.state.lanaguageConstants} />}
                    />

                    <Route
                        path="*"
                        element={<Navigate to="/login" replace />}
                    />
                </Routes>
            </BrowserRouter>
        );
      }
}

export default App;
